
(function ( $ ) {
 
    $.fn.crfi = function() {
		this.change(function() {
			if ($(this).attr('type') == "radio") {
				$("input[name="+$(this).attr('name')+"]").not(this).next(".crf").removeClass("checked");
			}
			if ($(this).prop('checked')) {
				$(this).next().addClass("checked");
			} else {
				$(this).next().removeClass("checked");
			}
		});
        this.not(".crf-i").each(function(i) {
            $(this).attr("id", "crf-input-"+i).css({ position: "absolute", left: -9999+"em" }).addClass("crf-i").next("label").addClass("crf").attr("for", "crf-input-"+i);
			if ($(this).prop('checked')) {
				$(this).next().addClass("checked");
			}
        });
    };

	var settings;
	function closeF() {
		if ($(".crf-sm.opened").length) {
			$(".crf-s.opened").removeClass("opened");
			$(".crf-sm.opened").removeClass("opened").hide();
			settings.close.call()
		}
	}

    var methods = {
        init : function(options) {
			settings = $.extend({
	          select: function(){},
	          done: function(){},
	          open: function(){},
	          close: function(){}
	        }, options);

			$(document).unbind("click.crfs").on("click.crfs", ".crf-s", function() {
				var currentItem = $("div[data-id="+$(this).attr("id")+"]");
				if (currentItem.is(":visible")) {
					closeF()
					return false;
				}
				closeF()
				var outh = $(this).outerHeight();
				var selectCl = $(this).find("select").attr("class");
				var offsetE = $(this).offset();
				var currHei = currentItem.show().height();
				currentItem.css({ position: "absolute", left: -9999+"em" });
				$(this).addClass("opened");
				currentItem.addClass("opened "+selectCl).css({ left: offsetE.left, top: (offsetE.top+outh+currHei > $(document).height()) ? offsetE.top-currHei : offsetE.top+outh, width: $(this).outerWidth() }).show();
			    settings.open.call()
			});
			$(document).click(function(e){
			    if( $(e.target).closest(".crf-sm.opened, .crf-s.opened").length > 0 ) {
			        return false;
			    }
		    	closeF();
			});


			$(window).resize(function(){
				var currentT = $(".crf-s.opened");
				if (currentT.length) {
					var currentItem = $(".crf-sm.opened");
					var outh = currentT.outerHeight();
					var offsetE = currentT.offset();
					var currHei = currentItem.height();
					currentItem.css({ left: offsetE.left, top: (offsetE.top+outh+currHei > $(document).height()) ? offsetE.top-currHei : offsetE.top+outh, width: currentT.outerWidth() });
				}
			});

			$(document).on("click.crfs", ".crf-sm li", function() {
				var currentMenu = $(this).parentsUntil(".crf-sm").parent().attr("data-id");
				var currentClass = $("#"+currentMenu).attr("class");
				$("#"+currentMenu).attr("class", "crf-s").addClass($(this).attr("class").replace("selected", "")).addClass(currentClass.replace("hided-s", "").replace("opened", "")).find(".option").html($(this).html());
				$("#"+currentMenu).find("select").children().prop('selected', false).eq($(this).index()).prop('selected', true).change();
				$(this).parentsUntil(".crf-sm").parent().find(".selected").removeClass("selected");
				$(this).addClass("selected");
				closeF()
			    settings.select.call()
				return false;
			});


	        this.each(function(i) {
		        if (!$(this).hasClass("hided-s")) {
					$(this).addClass("hided-s").hide().wrap("<span class='crf-s "+$(this).attr("class")+"' id='crf-s-"+i+"' />").parent().append("<span class='option'>"+$(this).find("option:selected").html()+"</span>");
					var menuList= $("<ul></ul>");

					$(this).children().each(function() {
						menuList.append("<li class='"+(($(this).attr('class') != undefined) ? $(this).attr('class')+"" : "") + (($(this).is(':selected')) ? " selected" : "") +"'><span class='link'>"+$(this).html()+"</span></li>");
					});
					$("<div class='crf-sm' data-id='crf-s-"+i+"'/>").append(menuList).appendTo("body")
				    settings.done.call()
		        };
	        });

        },
        hide : function( ) { closeF(); }
    };

    $.fn.crfs = function(methodOrOptions) {
        if ( methods[methodOrOptions] ) {
            return methods[ methodOrOptions ].apply( this, Array.prototype.slice.call( arguments, 1 ));
        } else if ( typeof methodOrOptions === 'object' || ! methodOrOptions ) {
            return methods.init.apply( this, arguments );
        }    
    };
}( jQuery ));

// $Id$

/**
 * @author Henri MEDOT
 * @version last revision 2009-12-01
 */

$.fn.extend({
  scrollbarPaper: function() {
    this.each(function(i) {
      var $this = $(this);
      var paper = $this.data('paper');
      if (paper == null) {

        var barWidth = function() {
          var div = $('<div style="width:50px;height:50px;overflow:hidden;position:absolute;top:-200px;left:-200px;"><div style="height:100px;"></div></div>');
          $('body').append(div);
          var w1 = $('div', div).innerWidth();
          div.css('overflow-y', 'scroll');
          var w2 = $('div', div).innerWidth();
          div.remove();
          return Math.max(w1 - w2, 17);
        }.call();

        $this.before('<div class="scrollbarpaper-container" style="width:' + barWidth + 'px"><div class="scrollbarpaper-track"><div class="scrollbarpaper-drag"><div class="scrollbarpaper-drag-top"></div><div class="scrollbarpaper-drag-bottom"></div></div></div></div>');
        paper = $this.prev();
        $this.append('<div style="clear:both;"></div>');
        var content = $('> :first', $this);
        content.css('overflow', 'hidden');

        $this.data('barWidth',   barWidth);
        $this.data('paper',      paper);
        $this.data('track',      $('.scrollbarpaper-track', paper));
        $this.data('drag',       $('.scrollbarpaper-drag', paper));
        $this.data('dragTop',    $('.scrollbarpaper-drag-top', paper));
        $this.data('dragBottom', $('.scrollbarpaper-drag-bottom', paper));
        $this.data('content',    content);
        $this.data('clearer',    $('> :last', $this));
        paper.hide();
      }

      var barWidth =   $this.data('barWidth');
      var track =      $this.data('track');
      var drag =       $this.data('drag');
      var dragTop =    $this.data('dragTop');
      var dragBottom = $this.data('dragBottom');
      var content =    $this.data('content');
      var clearer =    $this.data('clearer');

      var contentHeight = clearer.position().top - content.position().top;
      $this.data('height', $this.height());
      $this.data('contentHeight', contentHeight);
      $this.data('offset', $this.offset());

      $this.unbind();
      var ratio = $this.height() / contentHeight;
      if (ratio < 1) {

        paper.show();
        content.addClass('scrollbarpaper-visible');
        content.width($this.width() - content.innerWidth() + content.width() - barWidth-15);
        paper.height($this.height());
        var offset = $this.offset();

        var dragHeight = Math.max(Math.round($this.height() * ratio), dragTop.height() + dragBottom.height());
        drag.height(dragHeight);
        var updateDragTop = function() {
          drag.css('top', Math.min(Math.round($this.scrollTop() * ratio), $this.height() - dragHeight) + 'px');
        };
        updateDragTop();

        $this.scroll(function(event) {
          updateDragTop();
        });

        var unbindMousemove = function() {
          $('html').unbind('mousemove.scrollbarpaper');
        };
        drag.mousedown(function(event) {
          unbindMousemove();
          var offsetTop = event.pageY - drag.offset().top;
          $('html').bind('mousemove.scrollbarpaper', function(event) {
            $this.scrollTop((event.pageY - $this.offset().top - offsetTop) / ratio);
            return false;
          }).mouseup(unbindMousemove);
          return false;
        });
      }
      else {
        $this.unbind();
        paper.hide();
        content.removeClass('scrollbarpaper-visible');
        content.width($this.width() - content.innerWidth() + content.width()-15);
      }

      var setTimeout = function() {
        window.setTimeout(function() {
          var offset = $this.offset();
          var dataOffset = $this.data('offset');
          if (($this.height() != $this.data('height'))
           || (clearer.position().top - content.position().top != $this.data('contentHeight'))
           || (offset.top != dataOffset.top)
           || (offset.left != dataOffset.left)) {
            $this.scrollbarPaper();
          }
          else {
            setTimeout();
          }
        }, 200);
      };
      setTimeout();
    });
  }
});
