$(document).ready(function() {
	$("#menu > ul > li > a").click(function() {
		var href = $(this).attr("href");
		$(".body").removeClass("active");
		$(href).addClass("active");
		return false;
	});
	$(".body .close").click(function() {
		$(".body").removeClass("active");
		return false;
	});
	$(".text-scroll .inner").each(function() {
		$(this).scrollbarPaper();
	});
	$(".menu-trigger").click(function() {
		$("body").toggleClass("active-menu");
	});
	$("input[type=checkbox], input[type=radio]").crfi();
});
$(window).on('load', function() {
	window.setTimeout(function() {
		$("#header").addClass("fadeIn");
	}, 0);
});